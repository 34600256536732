
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Info } from '@/types/potion'
import { FileInfo, TypeInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile },
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class PotionAdd extends Vue {
  private typeList: TypeInfo[] = []
  private tabList = ['产品特性', '用法用量', '施药方法', '注意事项']
  private tabIndex = '0'
  private info: Info = {
    medicineName: '',
    medicineType: '',
    preventionObject: '',
    medicinePart: '',
    resourceList: [],
    productFeatures: '',
    usages: '',
    medicineMethod: '',
    attention: '',
    tradeName: ''
  }

  private rules = {
    medicineName: [{ required: true, message: '请输入药剂名称', trigger: ['blur', 'change'] }],
    medicineType: [{ required: true, message: '请选择药剂类别', trigger: ['change'] }],
    resourceList: [{ required: true, message: '请选择上传药剂图片', trigger: ['change'] }],
    productFeatures: [{ required: true, message: '请输入产品特性' }],
    usages: [{ required: true, message: '请输入用法用量' }],
    medicineMethod: [{ required: true, message: '请输入施药方法' }]
  }

  private editorProductFeatures: any = null
  private editorUsages: any = null
  private editorMedicineMethod: any = null
  private editorAttention: any = null
  private submitShow = false
  get medicineId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getTypeList()
    this.init()
    if (this.medicineId) {
      this.getDetail()
    }
  }

  destroyed () {
    // 销毁编辑器
    this.editorProductFeatures.destroy()
    this.editorUsages.destroy()
    this.editorMedicineMethod.destroy()
    this.editorAttention.destroy()
    this.editorProductFeatures = null
    this.editorUsages = null
    this.editorMedicineMethod = null
    this.editorAttention = null
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.thinktankTypeList, {
      dicType: 'medicineType'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.editorProductFeatures = initWangEdit('#productFeatures', { placeholder: '请输入产品特性' })
      this.editorProductFeatures.create()
      this.editorUsages = initWangEdit('#usages', { placeholder: '请输入用法用量' })
      this.editorUsages.create()
      this.editorMedicineMethod = initWangEdit('#medicineMethod', { placeholder: '请输入施药方法' })
      this.editorMedicineMethod.create()
      this.editorAttention = initWangEdit('#attention', { placeholder: '请输入注意事项' })
      this.editorAttention.create()
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.potion.detailDataMedicine, {
      medicineId: this.medicineId
    }).then(res => {
      this.info = res
      this.editorProductFeatures.txt.html(res.productFeatures || '')
      this.editorUsages.txt.html(res.usages || '')
      this.editorMedicineMethod.txt.html(res.medicineMethod || '')
      this.editorAttention.txt.html(res.attention || '')
    })
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    // 必填项赋值
    // 获取富文本内容
    this.info.productFeatures = this.editorProductFeatures.txt.html()
    this.info.usages = this.editorUsages.txt.html()
    this.info.medicineMethod = this.editorMedicineMethod.txt.html()
    this.tabIndex = this.info.productFeatures === '' ? '0' : this.info.usages === '' ? '1' : this.info.medicineMethod === '' ? '2' : this.tabIndex
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.medicineId ? this.$apis.potion.updateMedicine : this.$apis.potion.insertMedicine
        this.info.attention = this.editorAttention.txt.html()

        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'potionList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
